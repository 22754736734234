<template>
  <div class="questions">
    <div class="questions-wrapper">
      <!-- навигац. цепочка -->
      <div class="nav-chain">
        <div class="chain">
          <div class="breadcrumbs">
            <router-link :to="{ name: 'Groups' }" class="link"
              >Группы</router-link
            >
            <div class="divider">/</div>
            <router-link :to="{ path: `/categories/${group.id}` }" class="link"
              >Группа {{ group.title }}</router-link
            >
            <div class="divider">/</div>
            <div class="link disabled">Категория {{ category.title }}</div>
          </div>
          <!-- кнопка вызова модалки создания категории -->
          <v-btn
            color="white"
            class="btn"
            v-show="questions.length > 0"
            @click="addNewQuestion"
          >
            <v-img
              alt="login"
              contain
              src="@/assets/plus.svg"
              transition="scale-transition"
              width="37"
            /><span class="btn__text">Добавить</span>
          </v-btn>
        </div>
      </div>
      <!-- табилца с вопросами -->
      <div
        class="questions-table"
        v-if="questions.length > 0 || newQuestionShow"
      >
        <table class="table">
          <tr class="thead">
            <th :class="scrollPosition >= 135 ? 'shadow' : null">Вопрос</th>
            <th :class="scrollPosition >= 135 ? 'shadow' : null">Ответ</th>
            <!-- пустой для кнопок -->
            <th></th>
          </tr>
          <!-- новый вопрос -->
          <tr class="newQuestion" v-if="newQuestionShow">
            <td class="newQuestion__question">
              <v-textarea
                rows="3"
                auto-grow
                color="#11ACED"
                outlined
                label="Введите вопрос"
                name="name"
                type="text"
                v-bind:counter="maxQuestionLength"
                :rules="[rules.required, rules.question.max]"
                v-model="newQuestion.question"
              ></v-textarea>
            </td>
            <td class="newQuestion__answer">
              <v-textarea
                rows="3"
                auto-grow
                color="#11ACED"
                outlined
                label="Введите ответ"
                name="name"
                type="text"
                counter="2000"
                :rules="[rules.required, rules.answer.max]"
                v-model="newQuestion.answer"
              ></v-textarea>
              <div class="btns">
                <v-btn
                  style="height: 40px"
                  color="black"
                  text
                  @click="newQuestionShow = false"
                >
                  Отменить
                </v-btn>
                <v-btn
                  class="btns__submit"
                  @click="createQuestion"
                  :disabled="
                    newQuestion.question == '' ||
                      newQuestion.question.length > maxQuestionLength ||
                      newQuestion.answer == '' ||
                      newQuestion.answer.length > 2000
                  "
                >
                  Сохранить
                </v-btn>
              </div>
            </td>
          </tr>
          <!-- все вопросы -->
          <tr
            class="question"
            v-for="(question, index) in questions.slice(0, sliceValue)"
            :key="index"
          >
            <!-- вопрос -->
            <td
              class="question__question"
              v-if="question.id !== editedQuestion.id"
            >
              <div class="text">{{ question.question }}</div>
            </td>
            <td class="newQuestion__question" v-else>
              <v-textarea
                rows="3"
                auto-grow
                color="#11ACED"
                outlined
                label="Введите вопрос"
                name="name"
                type="text"
                v-bind:counter="maxQuestionLength"
                :rules="[rules.required, rules.question.max]"
                v-model="editedQuestion.question"
              ></v-textarea>
            </td>
            <!-- ответ -->
            <td
              class="question__answer"
              v-if="question.id !== editedQuestion.id"
            >
              <div class="question__answer-wrapper">
                <div class="text">{{ question.answer }}</div>
                <!-- кнопки удалить редактировать -->
                <div class="question__btns">
                  <v-btn
                    class="btn"
                    text
                    @click="editedQuestion = { ...question }"
                    ><v-icon size="25" color="rgba(0, 0, 0, 0.5)"
                      >mdi-pencil-outline</v-icon
                    ></v-btn
                  >
                  <v-btn
                    class="btn"
                    text
                    @click="
                      deletingId = question.id;
                      deleteModalShow = true;
                    "
                    ><v-icon size="25" color="rgba(0, 0, 0, 0.5)"
                      >mdi-delete-outline</v-icon
                    ></v-btn
                  >
                </div>
              </div>
            </td>
            <td class="newQuestion__answer" v-else>
              <v-textarea
                rows="3"
                auto-grow
                color="#11ACED"
                outlined
                label="Введите ответ"
                name="name"
                type="text"
                counter="2000"
                :rules="[rules.required, rules.answer.max]"
                v-model="editedQuestion.answer"
              ></v-textarea>
              <div class="btns">
                <v-btn
                  style="height: 40px"
                  color="black"
                  text
                  @click="editedQuestion = {}"
                >
                  Отменить
                </v-btn>
                <v-btn
                  class="btns__submit"
                  :disabled="
                    editedQuestion.question == '' ||
                      editedQuestion.question.length > maxQuestionLength ||
                      editedQuestion.answer == '' ||
                      editedQuestion.answer.length > 2000
                  "
                  @click="editQuestion()"
                >
                  Сохранить
                </v-btn>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- если нет категорий созданных -->
    <div class="noCards" v-show="questions.length === 0 && !newQuestionShow">
      <div class="text">
        Для добавления нового вопроса/ответа нажмите кнопку “Добавить”
      </div>
      <!-- кнопкa создания вопроса -->
      <v-btn color="white" class="btn" @click="addNewQuestion">
        <v-img
          alt="login"
          contain
          src="@/assets/plus.svg"
          transition="scale-transition"
          width="37"
        /><span class="btn__text">Добавить</span>
      </v-btn>
    </div>
    <!-- удаление вопроса -->
    <delete-modal
      :show="deleteModalShow"
      :type="'question'"
      @closed="deleteModalShow = false"
      @accepted="deleteQuestion(deletingId)"
    />
  </div>
</template>

<script>
import axios from "axios";
import deleteModal from "@/components/deleteModal";
export default {
  components: {
    deleteModal,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Категории билетов",
          disabled: false,
          href: "/groups",
        },
        {
          text: `Категория ${this.category ? this.category.title : ""}`,
          disabled: true,
          href: "/questions",
        },
      ],
      // текст редактируемой карточки в данный момент
      editedQuestion: {},
      newQuestionShow: false,
      newQuestion: {
        category_id: this.$route.params.id,
        question: "",
        answer: "",
      },
      deleteModalShow: false,
      deletingId: "",
      questions: [],
      category: {},
      group: {},
      maxQuestionLength: 120,

      // questionsTest: [],
      scrollPosition: null,
      sliceValue: 50,

      rules: {
        required: (value) => !!value || "Обязательное поле",
        question: {
          max: (v) =>
            v.length <= this.maxQuestionLength ||
            `Максимум ${this.maxQuestionLength} символов`,
        },
        answer: {
          max: (v) => v.length <= 2000 || "Максимум 2000 символов",
        },
      },
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      /* Если высота окна + высота прокрутки больше или равны высоте всего документа, то  */
      if (
        window.innerHeight + window.scrollY >= document.body.clientHeight &&
        this.questions.length > this.sliceValue
      ) {
        this.sliceValue += 50;
      }
    },
    addNewQuestion() {
      this.newQuestion = {
        category_id: this.$route.params.id,
        question: "",
        answer: "",
      };
      this.newQuestionShow = true;
    },
    createQuestion() {
      return new Promise((resolve, reject) => {
        axios
          .post("/questions", this.newQuestion)
          .then((resp) => {
            this.newQuestionShow = false;
            this.page = 1;
            this.getQuestions();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getQuestions() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/questions?category_id=${this.$route.params.id}`)
          .then((resp) => {
            this.questions = resp.data.reverse();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getCategory() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/categories/${this.$route.params.id}`)
          .then((resp) => {
            this.category = resp.data;
            resolve(resp);
          })
          .then(() => this.getGroup())
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getGroup() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/groups/${this.category.group_id}`)
          .then((resp) => {
            this.group = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    editQuestion() {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/questions/${this.editedQuestion.id}`, {
            question: this.editedQuestion.question,
            answer: this.editedQuestion.answer,
          })
          .then((resp) => {
            this.editedQuestion = {};
            this.getQuestions();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    deleteQuestion(id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/questions/${id}`)
          .then((resp) => {
            this.deleteModalShow = false;
            this.getQuestions();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  created() {
    this.getCategory();
    this.getQuestions();
    // for (let i = 1; i <= 200; i++) {
    //   this.questionsTest.push({
    //     answer: "d",
    //     category_id: "5ffd6aaf1806aea0d21f72ab",
    //     id: i,
    //     question: "d",
    //   });
    // }
  },
};
</script>

<style lang="scss" scoped>
.questions {
  position: relative;
  height: 100%;
  &-wrapper {
    padding: 0 170px 19px 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.nav-chain {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 11;
}
.chain {
  padding-right: 28px;
  padding-top: 40px;
  padding-bottom: 58px;
  text-indent: 12px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(54, 66, 165, 0.65);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.questions-table {
  background: white;
  border-radius: 5px;
  max-width: 1102px;
  align-self: center;
  width: 100%;
  // height: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 40px 13px 18px;
}
.pagination {
  margin-bottom: 13px;
}
.table {
  width: 100%;
  background: white;
  border-radius: 5px;
  // min-height: 823px;
  border-spacing: 0;
  // padding: 0px 40px 13px 18px;
  .thead {
    height: 48px;
    th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
      text-indent: 12px;
      // padding: 16px;
      position: sticky;
      top: 70px;
      background: white;
      z-index: 3;
      &.shadow {
        box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0.2) !important;
      }
    }
    th:nth-child(1) {
      width: 354px;
    }
  }

  tr.newQuestion {
    height: 186px;
  }
  .newQuestion {
    &__question {
      padding-right: 47px !important;
      .v-textarea {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    &__answer {
      .v-textarea {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    td {
      vertical-align: baseline;
      vertical-align: -webkit-baseline-middle;
      padding: 28px 0px 0px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  // кнопки сохранить отмена при редактировании и создании вопроса внутри таблицы
  .btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 17px;
    &__submit {
      color: white;
      background: #4caf50;
      box-shadow: 0px 1px 1px rgba(7, 73, 1, 0.14),
        0px 2px 1px rgba(7, 73, 1, 0.12), 0px 1px 3px rgba(7, 73, 1, 0.2);
      border-radius: 5px;
      width: 133px;
      height: 42px;
      margin-left: 28px;
    }
  }
  .question {
    &__question {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.87);

      .text {
        word-break: break-all;
        line-break: auto;
        max-width: 296px;
        margin: 16px 0 16px 16px;
      }
    }
    &__answer {
      max-width: 623px;
      &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
        max-width: 591px;
        max-height: 44px;
        margin: 16px;
        //   2 строки с точками в конце при оверфлове
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        box-orient: vertical;
      }
    }
    td {
      vertical-align: baseline;
      padding: 17px 0px 0px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    &__btns {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .btn {
        min-width: 32px !important;
        width: 32px !important;
        height: 32px !important;
        &:not(:last-child) {
          margin-bottom: 11px;
        }
      }
    }
  }
}
.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
  .btn {
    width: 167px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #3642a5;
      margin-left: 11px;
    }
  }
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  .divider {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .link {
    color: rgba(54, 66, 165, 0.65);
    font-size: 14px;
    line-height: 21px;
    text-indent: 12px;
    text-decoration: none;
    cursor: pointer;
    &.disabled {
      cursor: default;
      color: #3642a5;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eff2f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #29bffe;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #11aced;
}
</style>
